import { fetchAuthSession } from '@aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';

import { IUserPermissions } from '../../interfaces/IAuthState';

export enum UserRoles {
  ADMIN = 'APP-UK-CavendishAdmin',
  SUPPLY_CHAIN = 'APP-UK-CavendishSupplyChain',
  TERMINALS = 'APP-UK-CavendishTerminals',
  AGENT_MANAGER = 'APP-UK-CavendishAgentManager',
  AGENT = 'APP-UK-CavendishAgent',
  WAREHOUSE_OPS = 'APP-UK-CavendishWarehouseOps',
  BACK_OFFICE = 'APP-UK-CavendishBackOffice'
}

export const getUser = async (): Promise<any> => {
  const cognitoUser = await fetchAuthSession();

  return {
    accessToken: cognitoUser.tokens?.accessToken,
    idToken: cognitoUser.tokens?.idToken
  };
};

export function getUserPermissions(profile: string): IUserPermissions {
  const userRole = Object.values(UserRoles).find((r: string) =>
    profile.includes(r)
  );

  switch (userRole) {
    case UserRoles.ADMIN:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewOrderPayloadButton: true,
        viewPriorityModal: true,
        viewDispatchButton: true,
        viewUploadGoodsInFileButton: true,
        viewTradeInGoodsInButton: true,
        viewDownloadPreAlertFile: true,
        viewReleasePreordersButton: true,
        viewEditSubinventoryButton: true,
        viewUploadDpdDataButton: true,
        viewUploadMasterDeviceListButton: true,
        viewForecastingData: true,
        viewBulkReleasePreordersButton: true,
        viewDeviceEnrolmentReport: true
      };
    case UserRoles.TERMINALS:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewOrderPayloadButton: false,
        viewPriorityModal: true,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: true,
        viewEditSubinventoryButton: true,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: true,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.AGENT:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.AGENT_MANAGER:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.SUPPLY_CHAIN:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: true,
        viewConfigInfo: true,
        viewOrderPayloadButton: false,
        viewPriorityModal: true,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: true,
        viewEditSubinventoryButton: true,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: true,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.WAREHOUSE_OPS:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: false,
        viewConfigInfo: true,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: true,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    case UserRoles.BACK_OFFICE:
      return {
        viewSkuGroups: true,
        viewOrderReports: true,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
    default:
      return {
        viewSkuGroups: false,
        viewOrderReports: false,
        changeStockLevel: false,
        viewConfigInfo: false,
        viewOrderPayloadButton: false,
        viewPriorityModal: false,
        viewDispatchButton: false,
        viewUploadGoodsInFileButton: false,
        viewTradeInGoodsInButton: false,
        viewDownloadPreAlertFile: false,
        viewReleasePreordersButton: false,
        viewEditSubinventoryButton: false,
        viewUploadDpdDataButton: false,
        viewUploadMasterDeviceListButton: false,
        viewForecastingData: false,
        viewBulkReleasePreordersButton: false,
        viewDeviceEnrolmentReport: false
      };
  }
}

export default async function mountHubListener(
  setState: CallableFunction
): Promise<void> {
  Hub.listen('auth', async ({ payload }) => {
    switch (payload.event) {
      case 'signInWithRedirect': {
        const user = await getUser();
        setState({
          authState: 'signedIn',
          user,
          permissions: getUserPermissions(user?.idToken?.payload?.profile)
        });
        break;
      }
      case 'signInWithRedirect_failure':
        setState({
          authState: 'signedOut',
          user: undefined,
          error: payload.data
        });
        break;
      default:
        break;
    }
  });
}

export const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
